import fullsizeImagesUI from './fullsize';
import slidersUI from './sliders';
import mapsUI from './maps-ui';

;(function(window, document, $) {
	$('a[href^="tel"]').each(function() {
		const linkHref = $(this).attr('href');

		$(this).attr('href', linkHref.replace(/ /igm, ''));
	});

	$('.js-custom-select select').niceSelect();
	
	fullsizeImagesUI();

	slidersUI();

	mapsUI();

	$('.js-nav-btn').on('click', function(event) {
		event.preventDefault();
		
		$(this).toggleClass('active');
		$('.header .header__bottom').slideToggle();
	});

	$('.slider-for').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: false,
	  asNavFor: '.slider-nav',
	  infinite: false,
	});
	
	$('.slider-nav').slick({
	  slidesToShow: 4,
	  slidesToScroll: 1,
	  asNavFor: '.slider-for',
	  focusOnSelect: true,
	  infinite: false,
	});
})(window, document, window.jQuery);
